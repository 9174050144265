import { h, resolveComponent } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import { useStore } from 'vuex'

import DefaultLayout from '@/layouts/DefaultLayout'
// Middleware
import auth from './middleware/auth'
import guest from './middleware/guest'
const routes = [
  {
    path: '/',
    name: 'Home',
    component: DefaultLayout,
    redirect: '/dashboard',
    meta: {
      middleware: [auth],
    },
    children: [
      {
        path: '/dashboard',
        name: 'Dashboard',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('@/views/Dashboard.vue'),
      },
      {
        path: 'google-ads',
        name: 'Google Ads',
        meta: {
          middleware: [auth],
        },
        component: () => import('@/views/user/GoogleAds.vue'),
      },
      {
        path: 'google-display-ads',
        name: 'Google Display Ads',
        meta: {
          middleware: [auth],
        },
        component: () => import('@/views/user/GoogleAds.vue'),
      },
      {
        path: 'facebook-ads',
        name: 'Facebook Ads',
        meta: {
          middleware: [auth],
        },
        component: () => import('@/views/user/FacebookAds.vue'),
      },
      {
        path: 'facebook-ads/create',
        name: 'Create Facebook Ads',
        meta: {
          middleware: [auth],
        },
        component: () => import('@/views/user/FacebookAdsCreate.vue'),
      },
      {
        path: 'facebook-ads-d',
        name: 'Facebook Ads D',
        meta: {
          middleware: [auth],
        },
        component: () => import('@/views/user/FacebookAdsDisplay.vue'),
      },
      {
        path: 'facebook-ads-d/create',
        name: 'Create Facebook Ads D',
        meta: {
          middleware: [auth],
        },
        component: () => import('@/views/user/FacebookAdsCreateDisplay.vue'),
      },
      {
        path: 'monthly-budget',
        name: 'Monthly Budget',
        meta: {
          middleware: [auth],
        },
        component: () => import('@/views/user/Budget.vue'),
      },
      {
        path: 'settings',
        name: 'Settings',
        meta: {
          middleware: [auth],
        },
        component: () => import('@/views/user/Setting.vue'),
      },
      {
        path: 'images',
        name: 'Images',
        meta: {
          middleware: [auth],
        },
        component: () => import('@/views/contributor/Images.vue'),
      },
      {
        path: 'text',
        name: 'Text',
        meta: {
          middleware: [auth],
        },
        component: () => import('@/views/contributor/Text.vue'),
      },
      {
        path: 'image-text',
        name: 'Image & Text',
        meta: {
          middleware: [auth],
        },
        component: () => import('@/views/contributor/ImageText.vue'),
      },
      {
        path: 'manage-google-ads',
        name: 'Manage Google Ads',
        meta: {
          middleware: [auth],
        },
        component: () => import('@/views/contributor/ManageGoogleAds.vue'),
      },
      {
        path: 'manage-google-display-ads',
        name: 'Manage Google Display Ads',
        meta: {
          middleware: [auth],
        },
        component: () => import('@/views/contributor/ManageGoogleAds.vue'),
      },
    ],
  },
  {
    path: '/user',
    name: '',
    component: {
      render() {
        return h(resolveComponent('router-view'))
      },
    },
    children: [
      {
        path: 'questions',
        name: 'Questions',
        meta: {
          middleware: [auth],
        },
        component: () => import('@/views/user/Questions.vue'),
      },
      /*{
        path: 'plan/:id',
        name: 'Sign Payment',
        meta: {
          middleware: [auth],
        },
        component: SignPayment,
      }, */
      {
        path: 'plan',
        name: 'Sign Plan',
        meta: {
          middleware: [auth],
        },
        component: () => import('@/views/auth/SignPlan'),
      },
      {
        path: 'updateplan',
        name: 'Update Plan',
        meta: {
          middleware: [auth],
        },
        component: () => import('@/views/auth/UpdatePlan'),
      },
    ],
  },
  {
    path: '/',
    //redirect: '/pages/404',
    name: 'Pages',
    component: {
      render() {
        return h(resolveComponent('router-view'))
      },
    },
    children: [
      {
        path: '/reset-password',
        name: 'ResetPassword',
        component: () => import('@/views/auth/ResetPassword'),
      },
      {
        path: '/new-password',
        name: 'NewPassword',
        component: () => import('@/views/auth/NewPassword'),
      },
      {
        path: '404',
        name: 'Page404',
        component: () => import('@/views/Page404'),
      },
      {
        path: '500',
        name: 'Page500',
        component: () => import('@/views/Page500'),
      },
      {
        path: 'icons',
        name: 'icons',
        component: () => import('@/views/icons/CoreUIIcons.vue'),
      },
      {
        path: '/:pathMatch(.*)*',
        name: 'Page404',
        component: () => import('@/views/Page404'),
      },
    ],
  },
  {
    path: '/auth',
    redirect: '/auth/login',
    name: 'Auth',
    component: {
      render() {
        return h(resolveComponent('router-view'))
      },
    },
    children: [
      {
        path: 'login',
        name: 'Login',
        meta: {
          middleware: [guest],
        },
        component: () => import('@/views/auth/Login'),
      },
      {
        path: 'register',
        name: 'Register',
        meta: {
          middleware: [guest],
        },
        component: () => import('@/views/auth/Register'),
      },
    ],
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
})
router.beforeEach((to, from, next) => {
  document.title = 'AdMenu | ' + to.name

  if (!to.meta.middleware) {
    return next()
  }

  // Process the middleware
  const middleware = to.meta.middleware

  const context = { to, from, next, store: useStore() }

  // If the middleware is an array, you should iterate over it
  // and apply each middleware function in sequence.
  middleware.forEach((mw) => {
    if (typeof mw === 'function') {
      mw(context)
    }
  })
})
export default router
