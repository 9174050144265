export default {
  1: [
    {
      component: 'CNavItem',
      name: 'Dashboard',
      to: '/dashboard',
      icon: 'cil-chart-pie',
    },
    {
      component: 'CNavItem',
      name: 'Google Ads',
      to: '/google-ads',
      icon: 'cib-google',
    },
    {
      component: 'CNavItem',
      name: 'Google Display',
      to: '/google-display-ads',
      icon: 'cib-google',
    },
    {
      component: 'CNavItem',
      name: 'Facebook Ads D',
      to: '/facebook-ads-d',
      icon: 'cib-facebook',
    },
    {
      component: 'CNavItem',
      name: 'Monthly Budget',
      to: '/monthly-budget',
      icon: 'cil-dollar',
    },
    {
      component: 'CNavItem',
      name: 'Settings',
      to: '/settings',
      icon: 'cil-settings',
    },
    {
      component: 'CNavItem',
      name: 'Help',
      to: 'https://www.admenu.co/instructions',
      icon: 'cil-info',
    },
  ],
  2: [
    {
      component: 'CNavItem',
      name: 'Dashboard',
      to: '/dashboard',
      icon: 'cil-chart-pie',
    },
    {
      component: 'CNavItem',
      name: 'Images',
      to: '/images',
      icon: 'cil-image',
    },
    {
      component: 'CNavItem',
      name: 'Text',
      to: '/text',
      icon: 'cil-short-text',
    },
    {
      component: 'CNavItem',
      name: 'Image & Text',
      to: '/image-text',
      icon: 'cil-image',
    },
    {
      component: 'CNavItem',
      name: 'Google ads',
      to: '/manage-google-ads',
      icon: 'cib-google',
    },
    {
      component: 'CNavItem',
      name: 'Google Display ads',
      to: '/manage-google-display-ads',
      icon: 'cib-google',
    },
    {
      component: 'CNavItem',
      name: 'Settings',
      to: '/settings',
      icon: 'cil-settings',
    },
  ],
  3: [
    {
      component: 'CNavItem',
      name: 'Dashboard',
      to: '/dashboard',
      icon: 'cil-chart-pie',
    },
    {
      component: 'CNavItem',
      name: 'Google Search',
      to: '/google-ads',
      icon: 'cib-google',
    },
    {
      component: 'CNavItem',
      name: 'Google Display',
      to: '/google-display-ads',
      icon: 'cib-google',
    },
    {
      component: 'CNavItem',
      name: 'Facebook',
      to: '/facebook-ads-d',
      icon: 'cib-facebook',
    },
    {
      component: 'CNavItem',
      name: 'Budget and Publish',
      to: '/monthly-budget',
      icon: 'cil-dollar',
    },
    {
      component: 'CNavItem',
      name: 'Settings',
      to: '/settings',
      icon: 'cil-settings',
    },
    {
      component: 'CNavItem',
      name: 'Help',
      to: 'https://www.admenu.co/instructions',
      icon: 'cil-info',
      external: true,
    },
  ],
}
