import axios from '@/plugins/axios'

const data = {
  state: {
    categories: [],
    profileCategories: [],
    countries: [],
    state: [],
    cities: [],
    tags: [],
  },
  mutations: {
    setCategories(state, data) {
      state.categories = data
    },
    setProfileCategories(state, data) {
      state.profileCategories = data
    },
    setCountries(state, data) {
      state.countries = data
    },
    setState(state, data) {
      state.state = data
    },
    setCities(state, data) {
      state.cities = data
    },
    setTags(state, data) {
      state.tags = data
    },
  },
  getters: {
    getCategories(state) {
      return state.categories
    },
    getProfileCategories(state) {
      return state.profileCategories
    },
    getCountries(state) {
      return state.countries
    },
    getState(state) {
      return state.state
    },
    getCities(state) {
      return state.cities
    },
    getTags(state) {
      return state.tags
    },
  },
  actions: {
    fetchCategories({ commit }) {
      commit('setLoader', true)
      return new Promise((resolve, reject) => {
        axios
          .get('/data/categories')
          .then((res) => {
            commit('setCategories', res.data.data)
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    fetchMyCategories({ commit }) {
      commit('setLoader', true)
      return new Promise((resolve, reject) => {
        axios
          .get('/data/category-by-profile')
          .then((res) => {
            if (res.data.data) {
              commit('setCategories', res.data.data)
            }
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    fetchProfileCategories({ commit }, type = null) {
      commit('setLoader', true)
      return new Promise((resolve, reject) => {
        axios
          .get('/data/profile-categories', {
            params: type ? { type } : {},
          })
          .then((res) => {
            commit('setProfileCategories', res.data.data)
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    fetchCountries({ commit }) {
      commit('setLoader', true)
      return new Promise((resolve, reject) => {
        axios
          .get('/data/countries')
          .then((res) => {
            commit('setCountries', res.data.data)
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    fetchState({ commit }, id) {
      if (id) {
        commit('setLoader', true)
        return new Promise((resolve, reject) => {
          axios
            .get(`/data/countries/${id}/states`)
            .then((res) => {
              commit('setState', res.data.data)
              resolve(res)
            })
            .catch((error) => {
              reject(error)
            })
        })
      } else {
        commit('setState', [])
        return []
      }
    },
    fetchCities({ commit }, id) {
      if (id) {
        commit('setLoader', true)
        return new Promise((resolve, reject) => {
          axios
            .get(`/data/states/${id}/cities`)
            .then((res) => {
              commit('setCities', res.data.data)
              resolve(res)
            })
            .catch((error) => {
              reject(error)
            })
        })
      } else {
        commit('setCities', [])
        return []
      }
    },
    fetchTags({ commit }) {
      commit('setLoader', true)
      return new Promise((resolve, reject) => {
        axios
          .get('/data/tags')
          .then((res) => {
            commit('setTags', res.data.data)
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
  },
}

export default data
